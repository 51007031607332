import RecordingModel from '../model/RecordingModel';
import './Recorder.css';

class Recorder {
    constructor($controlParent, stream) {
        this.$controlParent = $controlParent;
        this.stream = stream;

        this.mediaRecorder = null;
        this.recordedBlobs = [];
        this.recordingTimeout = null;
        this.videoBlob = null;

        this.$video = null;
        this.$start = null;
        this.$stop = null;
        this.$overlayText = null;
        this.$ctaCaption = null;
        this.$ctaUrl = null;
        this.$submit = null;

        this.startRecording = this.startRecording.bind(this);
        this.stopRecording = this.stopRecording.bind(this);
        this.submitRecording = this.submitRecording.bind(this);
        this.onRecorderDataAvailable = this.onRecorderDataAvailable.bind(this);
        this.onRecorderStopped = this.onRecorderStopped.bind(this);

        this.rendered = false;

        this.init();
    }

    init() {
        this.initMediaRecorder();
    }

    initMediaRecorder() {
        let mediaRecorder;

        mediaRecorder = new MediaRecorder(this.stream);
        mediaRecorder.ondataavailable = this.onRecorderDataAvailable;
        mediaRecorder.onstop = this.onRecorderStopped;

        this.mediaRecorder = mediaRecorder;
    }

    mount() {
        this.render();
    }

    unmount() {
        this.teardownRender();
    }

    onRecorderDataAvailable(event) {
        if (event.data && event.data.size > 0) {
            this.recordedBlobs.push(event.data);
        }
    }

    onRecorderStopped() {
        this.videoBlob = new Blob(this.recordedBlobs, { type: 'video/webm' });
        this.$start.style.display = 'block';
        this.$stop.style.display = 'none';
        this.$submit.disabled = false;
    }

    startRecording() {
        this.$start.style.display = 'none';
        this.$stop.style.display = 'block';
        this.$error.classList.remove('reveal');
        this.$submit.disabled = true;
        this.recordedBlobs = [];
        this.mediaRecorder.start();
        this.recordingTimeout = setTimeout(() => {
            this.mediaRecorder.stop();
        }, 60000);
    }

    stopRecording() {
        let mediaRecorder = this.mediaRecorder;

        this.recordingTimeout && clearTimeout(this.recordingTimeout);
        if (mediaRecorder.state === 'recording') {
            mediaRecorder.stop();
        }
    }

    submitRecording() {
        if (this.videoBlob) {
            this.$controlParent.dispatchEvent(
                new CustomEvent('recordingReady')
            );
        } else {
            this.$error.classList.add('reveal');
        }
    }

    getRecording() {
        return new RecordingModel(
            this.videoBlob,
            this.$overlayText.value || '',
            this.$ctaCaption.value || '',
            this.$ctaUrl.value || ''
        );
    }

    render() {
        let $controlParent = this.$controlParent;

        this.rendered && this.teardownRender();

        $controlParent.innerHTML = `
            <div id="recorderForm">
                <h1>Record your story:</h1>

                <div class="story">
                    <video class="video" width="360" height="640" autoplay></video>

                    <div class="story-overlay">
                        <div class="overlay-text-container">
                            <h2 class="overlay-text">You forgot to make a video!</h2>
                        </div>
                    </div>
                </div>

                <button class="start-button" style="display: block;">Start Recording</button>
                <button class="stop-button" style="display: none;">Stop Recording</button>

                <hr/>

                <div class="flair">
                    <h2>Add some flair!</h2><br/>

                    <div class="field">
                        <label for="overlayText">Text (140 chars max):</label>
                        <input
                            type="text"
                            class="overlay-text"
                            name="overlayText"
                            maxlength="140"
                        />
                    </div>

                    <div class="field">
                        <label for="ctaCaption">Button Caption (24 chars max):</label>
                        <input
                            type="text"
                            class="cta-caption"
                            name="ctaCaption"
                            maxlength="24"
                        />
                    </div>

                    <div class="field">
                        <label for="ctaUrl">Button URL:</label>
                        <input type="text" class="cta-url" name="ctaUrl" />
                    </div>
                </div>

                <button type="button" class="submit-button">Submit</button><br/>
            </div>
        `;

        this.$video = $controlParent.querySelector('.video');
        this.$start = $controlParent.querySelector('.start-button');
        this.$stop = $controlParent.querySelector('.stop-button');

        this.$overlayText = $controlParent.querySelector(
            '.flair .overlay-text'
        );
        this.$ctaCaption = $controlParent.querySelector('.flair .cta-caption');
        this.$ctaUrl = $controlParent.querySelector('.flair .cta-url');

        this.$submit = $controlParent.querySelector('.submit-button');
        this.$error = $controlParent.querySelector('.story-overlay');

        this.$video.muted = true;
        this.$video.srcObject = this.stream;
        this.$start.onclick = this.startRecording;
        this.$stop.onclick = this.stopRecording;
        this.$submit.onclick = this.submitRecording;

        this.rendered = true;
    }

    teardownRender() {
        this.$controlParent.innerHTML = '';

        this.videoBlob = null;

        this.$video.srcObject = null;
        this.$start.onclick = null;
        this.$stop.onclick = null;
        this.$submit.onclick = null;

        this.$video = null;
        this.$start = null;
        this.$stop = null;
        this.$overlayText = null;
        this.$ctaCaption = null;
        this.$ctaUrl = null;
        this.$submit = null;
        this.$error = null;

        this.rendered = false;
    }
}

export default Recorder;
