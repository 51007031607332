import UserMediaConstraintsModel from './model/UserMediaConstraintsModel';
import UserMedia from './components/UserMedia';
import Recorder from './components/Recorder';
import Viewer from './components/Viewer';
import './App.css';
import './themes/pink-goblin.css';

class App {
    constructor() {
        this.userMedia;
        this.recorder;
        this.stream = null;

        this.$controlParent = document.getElementById('main');

        this.onStreamAvailable = this.onStreamAvailable.bind(this);
        this.onRecordingReady = this.onRecordingReady.bind(this);
        this.onRequestNewRecording = this.onRequestNewRecording.bind(this);

        this.start();
    }

    start() {
        this.userMedia = new UserMedia(
            this.$controlParent,
            new UserMediaConstraintsModel(true, true)
        );
        this.userMedia
            .getStream()
            .then(this.onStreamAvailable)
            .catch((e) => {
                console.log('App get stream error: ', e);
                this.requestPermission().then(this.onStreamAvailable);
            });
    }

    requestPermission() {
        return this.userMedia.requestPermission();
    }

    onStreamAvailable(stream) {
        this.startRecorder(stream);
        this.startViewer();
        this.recorder.mount();
    }

    startRecorder(stream) {
        this.recorder = new Recorder(this.$controlParent, stream);
        this.$controlParent.addEventListener(
            'recordingReady',
            this.onRecordingReady
        );
    }

    onRecordingReady() {
        let recording = this.recorder.getRecording();

        if (recording) {
            this.recorder.unmount();
            this.viewer.mount();
            this.viewer.setRecording(recording);
        }
    }

    startViewer() {
        this.viewer = new Viewer(this.$controlParent, null);
        this.$controlParent.addEventListener(
            'requestNewRecording',
            this.onRequestNewRecording
        );
    }

    onRequestNewRecording() {
        this.viewer.unmount();
        this.recorder.mount();
    }
}
export default App;
