import { escapeHTML, escapeURL } from '../util/TemplateHelper';
import './Viewer.css';

class Viewer {
    constructor($controlParent, recording) {
        this.$controlParent = $controlParent;
        this.recording = recording;

        this.rendered = false;
        this.videoUrl = null;

        this.$video = null;
        this.$play = null;
        this.$pause = null;
        this.$stop = null;
        this.$replay = null;
        this.$overlay = null;

        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
        this.stop = this.stop.bind(this);
        this.replay = this.replay.bind(this);
        this.onEnded = this.onEnded.bind(this);
        this.requestNewRecording = this.requestNewRecording.bind(this);
    }

    mount() {
        this.render();
    }

    unmount() {
        this.teardownRender();
    }

    setRecording(recording) {
        this.teardownRender();
        this.recording = recording;
        this.render();
    }

    play() {
        let $video = this.$video;

        $video &&
            $video.play().then(() => {
                this.$play.style.display = 'none';
                this.$pause.style.display = 'inline-block';
                this.$stop.style.display = 'inline-block';
                this.$replay.style.display = 'none';
            });
    }

    pause() {
        let $video = this.$video;

        $video && $video.pause();
        this.$play.style.display = 'inline-block';
        this.$pause.style.display = 'none';
        this.$stop.style.display = 'inline-block';
        this.$replay.style.display = 'none';
    }

    stop() {
        let $video = this.$video;

        if ($video) {
            $video.pause();
            $video.currentTime = 0;
            this.$play.style.display = 'inline-block';
            this.$pause.style.display = 'none';
            this.$stop.style.display = 'none';
            this.$replay.style.display = 'none';
            this.hideOverlay();
        }
    }

    replay() {
        let $video = this.$video;

        if ($video) {
            $video.currentTime = 0;
            this.hideOverlay();
            this.play();
        }
    }

    onEnded() {
        this.revealOverlay();
        this.$play.style.display = 'none';
        this.$pause.style.display = 'none';
        this.$stop.style.display = 'none';
        this.$replay.style.display = 'inline-block';
    }

    revealOverlay() {
        let $overlay = this.$overlay;

        if ($overlay) {
            $overlay.classList.add('reveal');
        }
    }

    hideOverlay() {
        let $overlay = this.$overlay;

        if ($overlay) {
            $overlay.classList.remove('reveal');
        }
    }

    requestNewRecording() {
        this.$controlParent.dispatchEvent(
            new CustomEvent('requestNewRecording')
        );
    }

    render() {
        this.recording && this.recording.videoBlob
            ? this.renderRecording()
            : this.renderWaiting();
    }

    renderRecording() {
        let $controlParent = this.$controlParent,
            markup;

        this.rendered && this.teardownRender();

        markup = `
            <div id="storyViewer">
                <h1>Your story:</h1>

                <div class="story">
                    <video class="video" width="360" height="640"></video>

                    <div class="story-overlay">
                    `;
        if (this.recording.overlayText) {
            markup += `
                        <div class="overlay-text-container">
                            <h2 class="overlay-text">${escapeHTML(this.recording.overlayText)}</h2>
                        </div>
            `;
        }
        if (this.recording.ctaUrl || this.recording.ctaCaption) {
            markup += `
                        <a class="cta" href="${
                            escapeURL(this.recording.ctaUrl) ||
                            'https://bots.social.balpha.de/@unicorn'
                        }" target="_blank">
                            ${
                                escapeHTML(this.recording.ctaCaption) ||
                                'Koop spullen!'
                            }
                        </a>
            `;
        }
        markup += `
                    </div>
                </div>

                <button class="play-button">Play</button>
                <button class="pause-button" style="display: none;">Pause</button>
                <button class="stop-button" style="display: none;">Stop</button>
                <button class="replay-button" style="display: none;">Replay</button>

                <hr/>

                <button class="new-button">Nieuwe story maken</button>
            </div>
        `;

        $controlParent.innerHTML = markup;

        this.$video = $controlParent.querySelector('.video');
        this.$play = $controlParent.querySelector('.play-button');
        this.$pause = $controlParent.querySelector('.pause-button');
        this.$stop = $controlParent.querySelector('.stop-button');
        this.$replay = $controlParent.querySelector('.replay-button');
        this.$overlay = $controlParent.querySelector('.story-overlay');
        this.$new = $controlParent.querySelector('.new-button');

        this.$play.onclick = this.play;
        this.$pause.onclick = this.pause;
        this.$stop.onclick = this.stop;
        this.$replay.onclick = this.replay;
        this.$video.onended = this.onEnded;
        this.$new.onclick = this.requestNewRecording;

        this.videoUrl = URL.createObjectURL(this.recording.videoBlob);
        this.$video.src = this.videoUrl;

        this.rendered = 'recording-view';
    }

    renderWaiting() {
        this.rendered && this.teardownRender();

        this.$controlParent.innerHTML = `
            <h1>No story submitted yet</h1>
        `;

        this.rendered = 'waiting-view';
    }

    teardownRender() {
        this.$controlParent.innerHTML = '';

        if (this.rendered === 'recording-view') {
            this.$video.onended = null;
            this.$play.onclick = null;
            this.$pause.onclick = null;
            this.$stop.onclick = null;
            this.$replay.onclick = null;

            URL.revokeObjectURL(this.$video.src);
            this.videoUrl = null;

            this.$video = null;
            this.$play = null;
            this.$pause = null;
            this.$stop = null;
            this.$replay = null;
            this.$overlay = null;

            this.rendered = false;
        }
    }
}
export default Viewer;
